









import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class UserDataItem extends Vue {
  @Prop() label!: string;
  @Prop() value!: string;
  @Prop({default: true}) colon!: boolean;
  @Prop({default: false}) labelAlignRight!: boolean;
}
