















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Message extends Vue {
  @Prop() private variant!: "warning" | "danger" | "info";
  @Prop() private title!: string;
  @Prop() private message!: string;
  @Prop() private message2!: string;
}
