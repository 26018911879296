














import {Component, Vue} from 'vue-property-decorator';
import Logo from "@/components/Logo.vue";
import Message from "@/components/Message.vue";

@Component({
  components: {Message, Logo}
})
export default class Result extends Vue {

  backToCleanForm(): void {
    // Add key to current URL, this will reload the page
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('key');
    window.location.search = urlParams.toString();
  }

}
