import {QuestionOption, SurveyQuestion} from "@/models/Question";
import {Survey} from "@/models/Survey";
import {Page} from "@/store/form";
import {Vue} from "vue-property-decorator";
import {UserData} from "@/models/UserData";

export function indexSort(a: SurveyQuestion | QuestionOption, b: SurveyQuestion | QuestionOption): number {
    if (a.idx < b.idx) return -1;
    if (a.idx > b.idx) return 1;
    return 0;
}

export function formatQuestionCount(count: number, total: number): string {
    return count.toString().padStart(2, '0') + '/' + total.toString().padStart(2, '0')
}

export function sortQuestions(form: Survey): void {
    if (form.questions) {
        form.questions.sort(indexSort);
        for (const question of form.questions) {
            if (question.radioQuestionOptions) {
                question.radioQuestionOptions.sort(indexSort);
            }
            if (question.checkboxQuestionOptions) {
                question.checkboxQuestionOptions.sort(indexSort);
            }
            if (question.multipleTextQuestionOptions) {
                question.multipleTextQuestionOptions.sort(indexSort);
            }
        }
    }
}

export function lightenDarkenColor(col: string, amt: number): string {
    // from: https://css-tricks.com/snippets/javascript/lighten-darken-color/
    const usePound = col.startsWith("#");
    const num = parseInt(usePound ? col.slice(1) : col, 16);
    const r = clamp((num >> 16) + amt, 0, 255);
    const b = clamp(((num >> 8) & 0x00FF) + amt, 0, 255);
    const g = clamp((num & 0x0000FF) + amt, 0, 255);
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0");
}

const clamp = (num: number, min: number, max: number): number => {
    return Math.min(Math.max(num, min), max);
}

export function setPage(comp: Vue, page: Page, scrollTop = false): void {
    comp.$store.commit('setPage', page);
    if (scrollTop) scrollToTop();
}

export function scrollToElementById(id: string): void {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }
}

export function scrollToTop(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function evaluateResultString(text: string, userData: UserData): string {
    const passedRegEx = /{PASSED\|(?<passedString>.*?)}/;
    const passedMatch = text.match(passedRegEx);

    const failedRegEx = /{FAILED\|(?<failedString>.*?)}/;
    const failedMatch = text.match(failedRegEx);

    const passedString = passedMatch?.groups?.passedString;
    const failedString = failedMatch?.groups?.failedString;
    if (passedString && failedString && userData.result) {
        text = text.replace('{PASSED|' + passedString + '}', userData.result.passed ? passedString : '');
        text = text.replace('<br />{FAILED|' + failedString + '}', userData.result.passed ? '' : failedString);
    }
    return text;
}

export function fillPlaceholders(survey: Survey, userData: UserData): string {
    if (!survey.mail) return '';
    const placeholderMap = {
        "TITEL": survey.title,
        "UNTERTITEL": survey.subTitle || '-',
        "BESCHREIBUNG": survey.description || '-',
        "ANREDE": salutationString(userData.salutation, survey),
        "VORNAME": userData.firstname || '-',
        "NACHNAME": userData.lastname || '-',
        "NAME": (userData.firstname || '-') + ' ' + (userData.lastname || '-'),
        "RUFNUMMER": userData.phone,
        "EMAIL": userData.email || '-',
        "FIRMA": userData.company || '-',
        "STRASSE": userData.addressStreet || '-',
        "STADT": userData.addressCity || '-',
        "VV_ID": userData.vvId || '-',
        "PARTNER_ID": userData.partnerId || '-',
        "KOMMENTAR": userData.comment || '',
        "DATUM": new Date(Date.now()).toLocaleString(),
        "ACCOUNTINHABER": survey.accountHolder,
        "E_PUNKTE": userData.result ? userData.result.reachedPoints.toString() : '0',
        "G_PUNKTE": userData.result ? userData.result.totalPoints.toString() : '0',
        "E_PROZENT": userData.result && userData.result.totalPoints > 0
            ? `${Math.round(userData.result.reachedPoints / userData.result.totalPoints * 100)}%`
            : '-'
    };

    let text = survey.mail.text.replaceAll('\n', '<br />');
    text = evaluateResultString(text, userData);

    let k: keyof typeof placeholderMap;
    for (k in placeholderMap) text = text.replaceAll('{' + k + '}', placeholderMap[k]);

    return text;
}

export function salutationString(salutation: string | undefined, survey: Survey, prefix = true): string {
    if (!salutation) return prefix ? 'Sehr geehrte*r' :'-';
    if (survey.language.toLowerCase() === 'de') {
        switch (salutation.toLowerCase()) {
            case 'male':
            case 'mr':
                return (prefix ? 'Sehr geehrter ' : '') + 'Herr';
            case 'female':
            case 'ms':
            case 'mrs':
                return (prefix ? 'Sehr geehrte ' : '') + 'Frau';
            default:
                return prefix ? 'Divers' : 'Sehr geehrte*r';
        }
    } else {
        switch (salutation.toLowerCase()) {
            case 'male':
            case 'mr':
                return (prefix ? 'Dear ' : '') + 'Mr';
            case 'female':
            case 'ms':
            case 'mrs':
                return (prefix ? 'Dear ' : '') + 'Mrs';
            default:
                return prefix ? 'Diverse' : 'Dear';
        }
    }
}