import Vue from 'vue'
import Vuex from 'vuex'
import {CustomFieldValue, FieldValue, Salutation, UserData} from "@/models/UserData";
import {Locale} from "date-fns";
import {default as localeDE} from "date-fns/locale/de";
import {default as localeEN} from "date-fns/locale/en-US";
import {Survey} from "@/models/Survey";
import SurveyService from "@/service/SurveyService";
import {SurveyQuestionAnswer} from "@/models/Response";

Vue.use(Vuex)

export type Page =
    "loading"
    | "imprint"
    | "form"
    | "notfound"
    | "error"
    | "start"
    | "submitted"
    | "deleted"
    | "banned"
    | "already_registered";

export type LogoPosition = "left" | "middle" | "center";

// Typing the state, see: https://stackoverflow.com/questions/53807294/how-is-the-correct-way-to-work-with-vuex-and-typescript
class State {
    page: Page = "loading";
    returnPage: Page = "form";
    logoUrl: string | undefined = undefined;
    bannerUrl: string | undefined = undefined;
    logoPosition: LogoPosition = "left";
    footerEnabled = false;
    footer = "";
    privacy = "";
    form: Survey | undefined = undefined;
    userData: UserData = {
        id: 0,
        dateCreated: 0,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        comment: "",
        partnerId: "",
        addressStreet: "",
        addressCity: "",
        salutation: Salutation.NONE,
        vvId: "",
        fieldValues: new Array<FieldValue>(),
        booleanValues: new Array<FieldValue>(),
        answers: new Array<SurveyQuestionAnswer>(),
        result: undefined,
        customFieldValues: new Array<CustomFieldValue>(),
        customUrlParams: new Array<{ key: string; value: string }>()
    };
    key: string | undefined = undefined;
    phone: string | undefined = undefined;
    locale: Locale = localeDE
}

export default new Vuex.Store({
    state: new State(),
    mutations: {
        setPage: (state, page: Page) => state.page = page,
        setKey: (state, key: string | undefined) => state.key = key,
        setPhone: (state, phone: string | undefined) => state.phone = phone,
        setUserData: (state, userData: UserData) => state.userData =  userData,
        setForm: (state, form: Survey) => {
            state.form = form
            const url = SurveyService.getUrl()
            if (form.branding?.logoFileExists) {
                state.logoUrl = url + "/branding-resources/" + form.branding.id + "/logo.png"
            }
            if (form.branding?.bannerFileExists) {
                state.bannerUrl = url + "/branding-resources/" + form.branding.id + "/banner.jpg"
            }
        },
        setLogoPosition: (state, position: LogoPosition) => state.logoPosition = position,
        setFooterEnabled: (state, footerEnabled: boolean) => state.footerEnabled = footerEnabled,
        setFooter: (state, footer: string) => state.footer = footer,
        setPrivacy: (state, privacy: string) => state.privacy = privacy,
        goToImprint: (state) => {
            if (state.page !== "imprint") {
                state.returnPage = state.page;
                state.page = "imprint";
            }
        },
        backFromImprint: (state) => state.page = state.returnPage
    },
    actions: {},
    getters: {
        userData: (state) => state.userData,
        key: (state) => state.key,
        phone: (state) => state.phone,
        page: (state) => state.page,
        logoUrl: (state) => state.logoUrl,
        logoPosition: (state) => state.logoPosition,
        footerEnabled: (state) => state.footerEnabled,
        footer: (state) => state.footer,
        privacy: (state) => state.privacy,
        bannerUrl: (state) => state.bannerUrl,
        form: (state) => state.form,
        locale: (state) => state.form?.language === "DE" ? localeDE : localeEN
    },
    modules: {}
})
