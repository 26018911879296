import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faEnvelope, faPhone, faSync, faBuilding, faFingerprint, faMapMarker, faBars, faCheckCircle, faCircle, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUser)
library.add(faEnvelope)
library.add(faPhone)
library.add(faSync)
library.add(faBuilding)
library.add(faFingerprint)
library.add(faMapMarker)
library.add(faBars)
library.add(faCheckCircle)
library.add(faCircle)
library.add(faCheckSquare)
library.add(faSquare)
library.add(faClock)

Vue.component('font-awesome-icon', FontAwesomeIcon)
