import IconFeatherUser from '@/assets/Icon_feather-user.svg';
import IconFeatherHome from '@/assets/Icon_feather-home.svg';
import IconFeatherInbox from '@/assets/Icon_feather-inbox.svg';
import IconFeatherMail from '@/assets/Icon_feather-mail.svg';
import IconFeatherMapPin from '@/assets/Icon_feather-map-pin.svg';
import IconFeatherStar from '@/assets/Icon_feather-star.svg';
import IconPhoneGrey from '@/assets/Telefon_Hellgrau 2.svg';

export const icons = [
    {icon: IconFeatherUser, name: 'icon-feather-user'},
    {icon: IconFeatherInbox, name: 'icon-feather-inbox'},
    {icon: IconFeatherMail, name: 'icon-feather-mail'},
    {icon: IconFeatherMapPin, name: 'icon-feather-map-pin'},
    {icon: IconFeatherStar, name: 'icon-feather-star'},
    {icon: IconPhoneGrey, name: 'icon-phone-grey'},
    {icon: IconFeatherHome, name: 'icon-feather-home'}
]