import axios from "axios";
import {Survey} from "@/models/Survey";
import {indexSort} from "@/utils/utils";
import {
    MultipleTextAnswer,
    SurveyQuestionAnswer,
    SurveyResponse,
    SurveyResponseAnswer, SurveyResult,
    ValueMap
} from "@/models/Response";
import {UserData} from "@/models/UserData";
import {QuestionType} from "@/models/Question";
import {da} from "date-fns/locale";

export default {
    getUrl(): string {
        return axios.defaults.baseURL || "";
    },

    getSurveyForm(url: string): Promise<Survey> {
        return axios.get('/participation', {params: {"url": url}}).then(response => response.data);
    },

    sendSurveyResponse(form: Survey, data: UserData): Promise<SurveyResult> {
        const response = this.createResponse(form, data);
        return axios.post('/participation/' + form.id, response).then(response => response.data);
    },

    sortSurveyQuestions(survey: Survey): Survey {
        survey.questions = survey.questions.sort(indexSort);
        return survey;
    },

    createResponse(form: Survey, data: UserData): SurveyResponse {
        const answers: Array<SurveyResponseAnswer> = [];
        data.answers.forEach((a: SurveyQuestionAnswer) => {
            answers.push(this.convertAnswer(a));
        });

        return {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            salutation: data.salutation,
            address: data.addressStreet + ' ' + data.addressCity,
            addressStreet: data.addressStreet,
            addressCity: data.addressCity,
            phone: data.phone,
            company: data.company,
            partnerId: data.partnerId,
            comment: data.comment,
            vvId: data.vvId,
            customFieldValues: data.fieldValues,
            customCheckboxValues: data.booleanValues,
            answers: answers,
            customValidatedFieldValues: data.customFieldValues,
            customUrlParams: data.customUrlParams
        }
    },

    convertAnswer(questionAnswer: SurveyQuestionAnswer): SurveyResponseAnswer {
        const responseAnswer: SurveyResponseAnswer = {
            id: questionAnswer.question.id,
            question: questionAnswer.question
        };
        const valueMap: ValueMap = {};
        switch (questionAnswer.question.type) {
            case QuestionType.CHECKBOX:
                responseAnswer.values = questionAnswer.answer as Array<number>;
                break;
            case QuestionType.RADIO:
                // With no user selection: questionAnswer.answer = []
                responseAnswer.values = Array.isArray(questionAnswer.answer) ? [] : [questionAnswer.answer as number];
                break;
            case QuestionType.MULTIPLE_TEXT:
                (questionAnswer.answer as Array<MultipleTextAnswer>).forEach((a: MultipleTextAnswer) => {
                    valueMap[a.id] = a.answer;
                })
                responseAnswer.valueMap = valueMap;
                break;
            case QuestionType.TEXT:
            default:
                responseAnswer.value = questionAnswer.answer as string;
                break;
        }
        return responseAnswer;
    },
    sortFields<T extends { global: boolean, index: number }>(fields: T[]): T[] {
        return fields.sort((a, b) => {
            if (a.global && !b.global) {
                return -1;
            } else if (!a.global && b.global) {
                return 1;
            } else {
                // Wenn beide global oder beide nicht global sind, sortiere nach dem Index
                return a.index - b.index;
            }
        });
    }
}
