











import { Component, Prop, Vue } from 'vue-property-decorator';
import {SurveySpeaker} from "@/models/Survey";
import SurveyService from "@/service/SurveyService";

@Component
export default class SpeakerCard extends Vue {
  @Prop() private speaker!: SurveySpeaker;
  @Prop() private formId!: number;

  get imageUrl(): string {
    return `${SurveyService.getUrl()}/survey/${this.formId}/speaker/${this.speaker.id}/photo`
  }
}
