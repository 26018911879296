import PhoneValidationService, {ValidatedPhoneNumber} from "@/service/PhoneValidationService";
import {QuestionType} from "@/models/Question";
import SurveyFormPage from "@/pages/SurveyForm.vue";
import {helpers} from "vuelidate/lib/validators";
import {MultipleTextAnswer, SurveyQuestionAnswer} from "@/models/Response";


// Custom validator as higher order function (https://vuelidate.js.org/#sub-extra-parameters)
export const phoneValidation = (form: SurveyFormPage, isoCode: string) => (value: string): Promise<boolean> =>
    PhoneValidationService.validatePhoneNumber(value, isoCode).then((validatedNumber: ValidatedPhoneNumber) => {
        // Store the international phone in store (will be later used in 'register' method)
        form.$store.commit('setPhone', validatedNumber.international);
        return validatedNumber.validNumber
    })

export const validateMultipleText = (val: string, answer: SurveyQuestionAnswer): boolean => {
    if (answer.question.type !== QuestionType.MULTIPLE_TEXT) return true;
    if (!answer.question.required) return true;
    return (answer.answer as Array<MultipleTextAnswer>).every(t => !(!t.answer));
}

export const validateVvid = helpers.regex('validateVvid', /(^\d{8}-\d{6}-\d{2}$|^$)/);

// eslint-disable-next-line no-control-regex
export const validateEmail = helpers.regex('validateEmail', /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9äöüß](?:[a-z0-9äöüß-]*[a-z0-9äöüß])?\.)+[a-z0-9äöüß]{2,}(?:[a-z0-9äöüß-]*[a-z0-9äöüß])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9äöüß-]*[a-z0-9äöüß]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i);

export const validateCity = helpers.regex('validateCity', /(\d|\w|\W)+ \w+/);