import {CustomField, CustomValidatedField, Field} from "@/models/Survey";
import {SurveyQuestionAnswer, SurveyResult} from "@/models/Response";

export interface UserData {
    id: number,
    dateCreated: number,
    firstname: string | undefined,
    lastname: string | undefined,
    email: string | undefined,
    phone: string,
    company: string | undefined,
    comment: string | undefined,
    partnerId: string | undefined,
    addressStreet: string | undefined,
    addressCity: string | undefined
    salutation: Salutation | undefined,
    vvId: string | undefined,
    answers: Array<SurveyQuestionAnswer>,
    result?: SurveyResult,
    fieldValues: Array<FieldValue>,
    booleanValues: Array<FieldValue>
    customFieldValues: Array<CustomFieldValue>,
    customUrlParams: Array<{key: string, value: string}>
}

export interface FieldValue {
    id: string,
    value: string
}
export interface CustomFieldValue {
    fieldId: number,
    value: string
}

export enum Salutation {
    NONE = '',
    MALE = 'male',
    FEMALE = 'female',
    DIVERS = 'divers'
}

export interface FieldWithValueString {
    field: Field,
    value: string
}

export interface FieldWithValueBoolean {
    field: Field,
    value: boolean
}

export interface CustomFieldWithValueString {
  field: CustomValidatedField;
  value: string;
}
