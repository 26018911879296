



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class User extends Vue {
  @Prop() private icon!: "user" | "map" | "home" | "mail" | "phone" | "star" | "inbox";
}
