import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/font-awesome'
import i18n from "@/plugins/i18n";
import App from './App.vue'
import axios from "axios"
import store from '@/store/form'
import Vuelidate from "vuelidate";
import {icons} from "@/utils/icons";

Vue.config.productionTip = false

let backendUrl = process.env.VUE_APP_BACKEND_URL
if (backendUrl === undefined) {
  console.log("No environment variable 'VUE_APP_BACKEND_URL' found. Use 'localhost:8080");
  backendUrl = "localhost:8080";
}

axios.defaults.baseURL = backendUrl;

Vue.use(Vuelidate);

icons.forEach((icon) => {
  Vue.component(icon.name, icon.icon);
});

new Vue({
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
