export interface SurveyQuestion {
    id: number,
    idx: number,
    title: string,
    description?: string,
    required: boolean,
    evaluate: boolean,
    type: QuestionType,
    checkboxQuestionOptions?: Array<QuestionOption>
    radioQuestionOptions?: Array<QuestionOption>
    multipleTextQuestionOptions?: Array<QuestionOption>
}

export enum QuestionType {
    TEXT = 'TEXT',
    MULTIPLE_TEXT = 'MULTIPLE_TEXT',
    CHECKBOX = 'CHECKBOX',
    RADIO ='RADIO'
}

export interface QuestionOption {
    id: number,
    idx: number,
    caption: string
    answerText?: string,
    rightAnswer?: boolean,
}

