









































import {Component, Vue} from 'vue-property-decorator';
import Message from './components/Message.vue';
import Loading from "@/pages/Loading.vue";
import Imprint from "@/pages/Imprint.vue";
import {default as SurveyFooter} from "@/components/Footer.vue";
import {Page} from "@/store/form";
import SurveyResult from "@/pages/SurveyResult.vue";
import Deleted from "@/pages/Deleted.vue";
import SurveyFormPage from "@/pages/SurveyForm.vue";
import {Survey} from "@/models/Survey";
import SurveyService from "@/service/SurveyService";
import {lightenDarkenColor, setPage, sortQuestions} from "@/utils/utils";

@Component({
  components: {
    Deleted,
    Result: SurveyResult,
    SurveyFooter,
    SurveyFormPage,
    Imprint,
    Loading,
    Message
  },
})
export default class App extends Vue {

  get isError(): boolean {
    return (
      this.page === 'already_registered' ||
      this.page === 'error' ||
      this.page === 'banned'
    )
  }

  mounted(): void {
    setPage(this, "loading");
    const url = window.location.pathname;
    if (url === "" || url === "/") {
      setPage(this, "start");
    } else {
      SurveyService.getSurveyForm(url).then(form => {
        sortQuestions(form);

        this.setBranding(form);
        this.setForm(form);
        setPage(this, "form")

        // Read initial form values from url params
        const urlParams = new URLSearchParams(window.location.search);
        const userData = this.handleUrlParams(urlParams, form);

        this.$store.commit('setUserData', userData);
      }).catch(e => {
        if (e.response?.status === 404) {
          setPage(this, "notfound")
        } else {
          console.log(e);
          setPage(this, "error");
        }
      })
    }
  }

  private handleUrlParams(urlParams: URLSearchParams, form: Survey) {
    const keys = ["firstname", "lastname", "email", "phone", "company", "comment", "partnerId", "addressStreet", "addressCity", "vvId", "salutation"];
    const userData = this.$store.getters.userData;
    const handledKeys: { [key: string]: boolean } = {};

    keys.forEach(key => {
      if (!urlParams.has(key)) return;
      userData[key] = urlParams.get(key);
      handledKeys[key] = true;
    });

    if (urlParams.has("id")) {
      userData["partnerId"] = urlParams.get("id") // Map urlparam id to partnerId
      handledKeys["id"] = true;
    }

    if (form.participantDataToSave?.customFields) {
      if (!userData.fieldValues) userData.fieldValues = []
      form.participantDataToSave?.customFields.forEach(field => {
        if (!urlParams.has(field.caption)) return;
        userData.fieldValues.push({id: field.id, value: urlParams.get(field.caption)});
        handledKeys[field.caption] = true;
      });
    }

    if (form.customFields) {
      form.customFields.forEach(field => {
        if (!urlParams.has(field.title)) return;
        userData.customFieldValues.push({
          fieldId: field.id,
          value: urlParams.get(field.title)
        });
        handledKeys[field.title] = true;
      });
    }

    const unhandledParams: { key: string, value: string }[] = [];
    urlParams.forEach((value, key) => {
      if (!handledKeys[key]) unhandledParams.push({key, value});
    });

    userData.customUrlParams = unhandledParams;
    return userData;
  }

  setBranding(form: Survey): void {
    if (form.branding === undefined) return;

    const r: any = document.querySelector(':root');
    if (r !== null) {
      const brandingData: any = JSON.parse(form.branding.data);
      if (brandingData.primaryBgColor) {
        r.style.setProperty('--primary-bg', brandingData.primaryBgColor);
        r.style.setProperty('--primary-bg-darker', lightenDarkenColor(brandingData.primaryBgColor, -20));
      }
      if (brandingData.primaryTextColor) {
        r.style.setProperty('--primary-text', brandingData.primaryTextColor);
      }
      if (brandingData.headerBgColor) {
        r.style.setProperty('--header-bg', brandingData.headerBgColor);
      }
      if (brandingData.headerTextColor) {
        r.style.setProperty('--header-text', brandingData.headerTextColor);
      }
      if (brandingData.logoPosition === "left") this.$store.commit('setLogoPosition', "left")
      if (brandingData.logoPosition === "middle") this.$store.commit('setLogoPosition', "middle")
      if (brandingData.logoPosition === "right") this.$store.commit('setLogoPosition', "right")
      this.$store.commit('setFooterEnabled', form.branding.footerEnabled || false);
      this.$store.commit('setFooter', form.branding.footer || "");
      this.$store.commit('setPrivacy', form.branding.privacy || "");
    }
  }

  setForm(form: Survey): void {
    this.$store.commit('setForm', form);
    this.$i18n.locale = form.language.toLowerCase(); // 'de' or 'en'
    document.title = form.title;
  }

  get form(): Survey | undefined {
    return this.$store.getters.form;
  }

  get page(): Page {
    return this.$store.getters.page;
  }

}
