








import {Component, Prop, Vue} from 'vue-property-decorator';
import {SurveySpeaker, Survey} from "@/models/Survey";
import SpeakerCard from "@/components/SpeakerCard.vue";

@Component({
  components: {
    SpeakerCard
  },
})
export default class SpeakerBlock extends Vue {

  mounted() {
    console.log(this.form)
  }

  get form(): Survey | undefined {
    return this.$store.getters.form;
  }

  get sortedSpeaker():Array<SurveySpeaker> {
    return this.form ? this.form.speakers.sort((s1:SurveySpeaker, s2:SurveySpeaker) => {
      if (s1.idx < s2.idx) return -1;
      if (s1.idx > s2.idx) return 1;
      return 0;
    }) : [];
  }
}
