



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Survey} from "@/models/Survey";

@Component
export default class Banner extends Vue {
  @Prop() private title!: string;
  @Prop() private subTitle!: string;
  @Prop() private useBottomBorderRadius!: boolean;

  get imageUrl(): string | undefined {
    return this.$store.getters.bannerUrl;
  }

  get form(): Survey | undefined {
    return this.$store.getters.form;
  }

  get showStandardImage(): boolean {
    return this.form?.branding === undefined;
  }

}
